<script setup>
import TextEditorVue from '@/components/TextEditor.vue'
import LoadingVue from '@/components/ui/Loading.vue'

import { getContentById, saveContent, updateContent } from '@/api/admin/contents.js'
import { ref, onMounted } from 'vue'
import { useRoute, useRouter } from 'vue-router'

const propsDataKo = ref('')
const propsDataEn = ref('')

const koEditor = ref(null)
const enEditor = ref(null)
const titleRef = ref(null)
const boardData = ref({
  boardName: 'content',
  boardId: ''
})

const route = useRoute()
const router = useRouter()
const isLoading = ref(true)

const id = route.params.id

const goBack = () => {
  router.replace('/admin/contents')
}

const emitData = async ({ getHtmlAfterSaveImages, editor }) => {
  const contentId = boardData.value.boardId
  const title = titleRef.value.value
  const content = await getHtmlAfterSaveImages(boardData.value)

  const data = { contentId, title }
  if (koEditor.value.editor === editor) data.content = content
  if (enEditor.value.editor === editor) data.contentEn = content

  const result = await updateContent(data)
  if (!result) return alert('Error')
}

const update = async () => {
  await koEditor.value.saveContent()
  await enEditor.value.saveContent()

  alert('저장되었습니다.')
  goBack()
}
const create = async (data) => {
  const result = await saveContent(data)
  if (result.code) return alert(result.message)

  boardData.value.boardId = result.contentId
  await update()
}

const formSubmitHandler = async (e) => {
  isLoading.value = true
  const title = titleRef.value.value

  const data = {
    title
  }

  if (id) await update(data)
  else await create(data)

  isLoading.value = false
}

onMounted(async () => {
  if (!id) return (isLoading.value = false)

  const result = await getContentById(id)
  isLoading.value = false
  boardData.value.boardId = id
  titleRef.value.value = result.title
  boardData.value.boardId = result.contentId

  propsDataKo.value = result.content ?? ' '
  propsDataEn.value = result.contentEn ?? ' '
})
</script>

<template>
  <LoadingVue v-if="isLoading" />
  <form class="wf__admin-content" @submit.prevent="formSubmitHandler">
    <div class="wf__button-group">
      <button type="button" class="e-btn wf__btn btn-primary" @click="goBack">목록</button>
      <button type="submit" class="e-btn wf__btn btn-success">저장</button>
    </div>
    <div class="adminTable">
      <table>
        <colgroup>
          <col width="10%" />
          <col width="90%" />
        </colgroup>

        <tbody>
          <tr>
            <th>제목</th>
            <td><input type="text" ref="titleRef" class="wf__admin-input" placeholder="" /></td>
          </tr>
          <tr>
            <th>한글</th>
            <td>
              <TextEditorVue
                :height="`30em`"
                v-if="propsDataKo || !id"
                :hideSave="true"
                :propsData="propsDataKo"
                ref="koEditor"
                :boardData="boardData"
                @emitData="emitData"
              />
            </td>
          </tr>
          <tr>
            <th>영문</th>
            <td>
              <TextEditorVue
                :height="`30em`"
                v-if="propsDataEn || !id"
                :hideSave="true"
                :propsData="propsDataEn"
                ref="enEditor"
                :boardData="boardData"
                @emitData="emitData"
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </form>
</template>

<style scoped>
.wf__admin-content {
  max-width: 1280px;
}
.wf__button-group {
  display: flex;
  justify-content: flex-end;
  gap: 0.5rem;
  margin-bottom: 1rem;
}
.wf__admin-input {
  outline: none;
  border: 1px solid var(--wf-gray-color);
  border-radius: 0.25em;
  padding: 0.25em 0.5em;
}
.adminTable th {
  text-align: center;
}
</style>
